import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    brands: {},
    isLoading: false,
   error :null
}

const getBrandsSlice = createSlice({
    name: 'brands',
    initialState,
    reducers:{
        brandsPending: (state) => {
            state.isLoading = true;
        },
        brandsSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.brands = payload?.result;
            state.error = null;
        },
        brandsFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {brandsPending, brandsSuccess, brandsFail} = getBrandsSlice.actions

export default getBrandsSlice.reducer