import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addAdProduct: {},
    isLoading: false,
    error: null
}

const addAdProductSlice = createSlice({
    name: 'addAdProduct',
    initialState,
    reducers:{
        addAdProductPending: (state) => {
            state.isLoading = true;
        },
        addAdProductSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addAdProduct = payload?.add;
            state.error = null;
        },
        addAdProductFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addAdProductPending, addAdProductSuccess, addAdProductFail} = addAdProductSlice.actions

export default addAdProductSlice.reducer