import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteSupplier: {},
    isLoading: false,
   error : null
}

const deleteSupplierSlice = createSlice({
    name: 'deleteSupplier',
    initialState,
    reducers:{
        deleteSupplierPending: (state) => {
            state.isLoadingSuppliers = true;
        },
        deleteSupplierSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteSupplier = payload?.deleted;
            state.error = null;
        },
        deleteSupplierFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteSupplierPending, deleteSupplierSuccess, deleteSupplierFail} = deleteSupplierSlice.actions

export default deleteSupplierSlice.reducer