import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getFeaturedShops: {},
    isLoadingFeaturedShops: false,
    errorGetFeaturedShops: ''
}

const getFeaturedShopSlice = createSlice({
    name: 'getFeaturedShops',
    initialState,
    reducers:{
        getFeaturedShopsPending: (state) => {
            state.isLoadingFeaturedShops = true;
        },
        getFeaturedShopsSuccess: (state, {payload}) => {

            state.isLoadingFeaturedShops = false;
            state.getFeaturedShops = payload?.result;
            state.errorGetFeaturedShops = '';
        },
        getFeaturedShopsFail: (state, {payload}) => {
            state.isLoadingFeaturedShops = false;
            state.errorGetFeaturedShops = payload;
        }
    }
})

export const {getFeaturedShopsPending, getFeaturedShopsSuccess, getFeaturedShopsFail} = getFeaturedShopSlice.actions

export default getFeaturedShopSlice.reducer