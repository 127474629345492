import { configureStore } from '@reduxjs/toolkit';
import headerSlice from '../features/common/headerSlice';
import modalSlice from '../features/common/modalSlice';
import rightDrawerSlice from '../features/common/rightDrawerSlice';
import leadsSlice from '../features/leads/leadSlice';
import getProductsReducer from "../features/products/productSlices/productSlice";
import LoginReducer  from "../features/user/userSlices/LoginSlice"
import getUsersReducer from "../features/settings/team/userSlices/userSlice"
import getCategoriesReducer from "../features/categories/categorySlices/categorySlice"
import orderReducer from "../features/transactions/slices/orderSlice"
import profileReducer from '../features/settings/profilesettings/slices/profileSlice';
import addProductReducer from '../features/products/productSlices/addProductSlice'
import LogoutReducer from '../features/settings/team/userSlices/LogoutSlice'
import AddUserReducer from '../features/settings/team/userSlices/AddUserSlice'
import UpdateUserReducer from '../features/settings/team/userSlices/EditUserSlice'
import customerReducer from '../features/settings/customers/customerSlices/customerSlice';
import supplierReducer from '../features/settings/suppliers/supplierSlices/supplierSlice';
import  addSupplierReducer from '../features/settings/suppliers/supplierSlices/addSupplierSlice'
import  updateSupplierReducer from '../features/settings/suppliers/supplierSlices/updateSupplierSlice'
import  deleteSupplierReducer from '../features/settings/suppliers/supplierSlices/deleteSupplierSlice'
import addCategoryReducer from '../features/categories/categorySlices/addCategorySlice';
import editCategoryReducer from '../features/categories/categorySlices/editCategorySlice';
import deleteCategoryReducer from '../features/categories/categorySlices/deleteCategorySlice';
import deleteProductReducer from '../features/products/productSlices/deleteProductSlice';
import updateProductReducer from '../features/products/productSlices/updateProductSlice';
import roleReducer from '../features/roles/roleSlices/roleSlice'
import addRoleReducer from '../features/roles/roleSlices/addRoleSlice';
import updateRoleReducer from '../features/roles/roleSlices/editRoleSlice';
import deleteRoleReducer from '../features/roles/roleSlices/deleteRoleSlice';
import dashboardReducer from '../features/dashboard/dashboardSlices/dashboardSlice';
import orderStatusReducer from '../features/transactions/slices/orderStatusSlice';
import retailerReducer from '../features/settings/retailers/retailerSlices/retailerSlice';
import editRetailerReducer from '../features/settings/retailers/retailerSlices/updateRetailerSlice'
import getBrandReducer from '../features/brands/brandSlices/brandSlice'
import addBrandReducer from '../features/brands/brandSlices/addBrandSlice';
import editBrandReducer from '../features/brands/brandSlices/editBrandSlice';
import deleteBrandReducer from '../features/brands/brandSlices/deleteBrandSlice';
import addRetailerReducer from '../features/settings/retailers/retailerSlices/addRetailerSlice';
import getPricesReducer from '../features/prices/priceSlices/priceSlice'
import addPriceReducer from '../features/prices/priceSlices/addPriceSlice'
import updatePriceReducer from '../features/prices/priceSlices/updatePriceSlice'
import getCatalogueReducer  from '../features/catalogues/catalogueSlices/catalogueSlice'
import addCatalogueReducer from '../features/catalogues/catalogueSlices/addCatalogueSlice';
import editCatalogueReducer from '../features/catalogues/catalogueSlices/editCatalogueSlice';
import deleteCatalogueReducer from '../features/catalogues/catalogueSlices/deleteCatalogueSlice';
import getBannnersReducer from '../features/banners/bannersSlices/bannerSlice'
import addBannerReducer from '../features/banners/bannersSlices/addBannerSlice'
import editBannerReducer from '../features/banners/bannersSlices/editBannerSlice'
import deleteBannerReducer from '../features/banners/bannersSlices/deleteBannerSlice'
import getAdsReducer from '../features/ads/adsSlices/adSlice'
import addAdReducer from '../features/ads/adsSlices/addAdSlice'
import editAdReducer from '../features/ads/adsSlices/editAdSlice'
import deleteAdReducer from '../features/ads/adsSlices/deleteAdSlice'
import featuredShopReducer from '../features/featured-shops/featuredSlices/featuredShopSlice';
import featuredProductsReducer from '../features/featured-shops/featuredSlices/featuredProductsSlice';
import featuredCategoriesReducer from '../features/featured-shops/featuredSlices/featuredCategoriesSlice';
import addFeaturedProductReducer from '../features/featured-shops/featuredSlices/addFeaturedProductSlice';
import updateFeaturedProductReducer from '../features/featured-shops/featuredSlices/updateFeaturedProductSlice';
import addFeaturedCategoryReducer from '../features/featured-shops/featuredSlices/addFeaturedCategorySlice';
import updateFeaturedCategoryReducer from '../features/featured-shops/featuredSlices/updateFeaturedCategorySlice';
import updateFeaturedCategoryStatusReducer from '../features/featured-shops/featuredSlices/updateFeaturedCategoryStatusSlice';
import addFeaturedShopReducer from '../features/featured-shops/featuredSlices/addFeaturedShopSlice';
import updateFeaturedShopReducer from '../features/featured-shops/featuredSlices/updateFeaturedShopSlice';
import updateFeaturedShopStatusReducer from '../features/featured-shops/featuredSlices/updateFeaturedShopStatusSlice';
import addAdProductReducer from '../features/ads/adsSlices/addAdProductSlice';
import editAdProductReducer from '../features/ads/adsSlices/editAdProductSlice';
import deleteRetailerReducer from '../features/settings/retailers/retailerSlices/deleteRetailerSlice';
import deleteFeaturedProductReducer from '../features/featured-shops/featuredSlices/deleteFeaturedProductSlice';
import featuredProductStatusReducer from '../features/featured-shops/featuredSlices/featuredProductStatusSlice';


const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  getProductsList: getProductsReducer,
  getUserList: getUsersReducer,
  categoryList : getCategoriesReducer,
  brandList : getBrandReducer,
  ordersList: orderReducer,
  authProfile: profileReducer,
  authUser : LoginReducer,
  addproducts : addProductReducer,
  addcategories: addCategoryReducer,
  logout : LogoutReducer,
  adduser : AddUserReducer,
  edituser: UpdateUserReducer,
  customerlist: customerReducer,
  supplierlist: supplierReducer,
  addsupplier: addSupplierReducer,
  editsupplier: updateSupplierReducer,
  deletesSupplier: deleteSupplierReducer,
  editcategory: editCategoryReducer,
  deletesCategory: deleteCategoryReducer,
  addbrands: addBrandReducer,
  editbrand: editBrandReducer,
  deletesBrand: deleteBrandReducer,
  updatingProduct: updateProductReducer,
  deletingProduct: deleteProductReducer,
  rolelist: roleReducer,
  addrole: addRoleReducer,
  editrole: updateRoleReducer,
  deleterole: deleteRoleReducer,
  dash: dashboardReducer,
  orderUpdate: orderStatusReducer,
  retailers:retailerReducer,
  addretailer: addRetailerReducer,
  editretailer:editRetailerReducer,
  prices: getPricesReducer,
  addprice: addPriceReducer,
  updateprice: updatePriceReducer,
  catalogueList : getCatalogueReducer,
  addcatalogues : addCatalogueReducer,
  editcatalogue : editCatalogueReducer,
  deletesCatalogue : deleteCatalogueReducer,
  bannerList : getBannnersReducer,
  addbanners : addBannerReducer,
  editbanner : editBannerReducer,
  deletesBanner : deleteBannerReducer,
  adList: getAdsReducer,
  addads : addAdReducer,
  editad : editAdReducer,
  deletesAd : deleteAdReducer,
  getFeaturedShopsList: featuredShopReducer,
  getFeaturedProductsList: featuredProductsReducer,
  getFeaturedCategoriesList: featuredCategoriesReducer,
  addFeaturedProduct: addFeaturedProductReducer,
  addFeaturedCategory: addFeaturedCategoryReducer,
  updateFeturedCaregory : updateFeaturedCategoryReducer,
  featuredCategoryStatus : updateFeaturedCategoryStatusReducer,
  addFeaturedShop: addFeaturedShopReducer,
  updateFeaturedShop: updateFeaturedShopReducer,
  featuredShopStatus: updateFeaturedShopStatusReducer,
  updatefeaturedproduct: updateFeaturedProductReducer,
  addadproduct : addAdProductReducer,
  editadproduct: editAdProductReducer,
  deletesretailer: deleteRetailerReducer,
  deletesfeaturedproduct: deleteFeaturedProductReducer,
  changefeaturedproductstatus: featuredProductStatusReducer
};

export default configureStore({
  reducer: combinedReducer,
});
