import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editBanner: {},
    isLoading: false,
    error: null
}

const editBannerSlice = createSlice({
    name: 'editBanner',
    initialState,
    reducers:{
        editBannerPending: (state) => {
            state.isLoading = true;
        },
        editBannerSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.editBanner = payload?.response;
            state.error = null;
        },
        editBannerFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {editBannerPending, editBannerSuccess, editBannerFail} = editBannerSlice.actions

export default editBannerSlice.reducer