import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addSupplier: {},
    isLoading: false,
     error : null
}

const addSupplierSlice = createSlice({
    name: 'addSupplier',
    initialState,
    reducers:{
        addSupplierPending: (state) => {
            state.isLoadingSuppliers = true;
        },
        addSupplierSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addSupplier = payload?.supplier;
            state.error = null;
        },
        addSupplierFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addSupplierPending, addSupplierSuccess, addSupplierFail} = addSupplierSlice.actions

export default addSupplierSlice.reducer