import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editAd: {},
    isLoading: false,
    error: null
}

const editAdSlice = createSlice({
    name: 'editAd',
    initialState,
    reducers:{
        editAdPending: (state) => {
            state.isLoading = true;
        },
        editAdSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.editAd = payload?.response;
            state.error = null;
        },
        editAdFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {editAdPending, editAdSuccess, editAdFail} = editAdSlice.actions

export default editAdSlice.reducer