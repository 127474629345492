import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addFeaturedShops: {},
    isLoading: false,
     error : null
}

const addFeaturedShopSlice = createSlice({
    name: 'addFeaturedShops',
    initialState,
    reducers:{
        addFeaturedShopPending: (state) => {
            state.isLoading = true;
        },
        addFeaturedShopSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addFeaturedShops = payload?.result;
            state.error = null;
        },
        addFeaturedShopFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addFeaturedShopPending, addFeaturedShopSuccess, addFeaturedShopFail} = addFeaturedShopSlice.actions

export default addFeaturedShopSlice.reducer