import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateFeaturedCategoryStatus: {},
    isLoadingFeaturedCategoryStatus: false,
    errorFeaturedCategoryStatus : null
}

const updateFeaturedCategoryStatusSlice = createSlice({
    name: 'updateFeaturedCategoryStatus',
    initialState,
    reducers:{
        updateFeaturedCategoryStatusPending: (state) => {
            state.isLoadingFeaturedCategoryStatus = true;
        },
        updateFeaturedCategoryStatusSuccess: (state, {payload}) => {
            state.isLoadingFeaturedCategoryStatus = false;
            state.updateFeaturedCategoryStatus = payload?.result;
            state.errorFeaturedCategoryStatus = null;
        },
        updateFeaturedCategoryStatusFail: (state, {payload}) => {
            state.isLoadingFeaturedCategoryStatus = false;
            state.errorFeaturedCategoryStatus = payload;
        }
    }
})

export const {updateFeaturedCategoryStatusPending, updateFeaturedCategoryStatusSuccess, updateFeaturedCategoryStatusFail} = updateFeaturedCategoryStatusSlice.actions

export default updateFeaturedCategoryStatusSlice.reducer