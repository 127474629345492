import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editCatalogue: {},
    isLoading: false,
    error: null
}

const editCatalogueSlice = createSlice({
    name: 'editCatalogue',
    initialState,
    reducers:{
        editCataloguePending: (state) => {
            state.isLoading = true;
        },
        editCatalogueSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.editCatalogue = payload?.response;
            state.error = null;
        },
        editCatalogueFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {editCataloguePending, editCatalogueSuccess, editCatalogueFail} = editCatalogueSlice.actions

export default editCatalogueSlice.reducer