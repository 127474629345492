import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customers: {},
    loading: false,
 error : null
}

const getCustomers = createSlice({
    name: 'customers',
    initialState,
    reducers:{
        customerPending: (state) => {
            state.loading = true;
        },
        customerSuccess: (state, {payload}) => {
            state.loading = false;
            state.customers = payload;
            state.error = null;
        },
        customerFail: (state, {payload}) => {
            state.loading = false;
            state.error = payload;
        }
    }
})

export const {customerPending, customerSuccess, customerFail} = getCustomers.actions

export default getCustomers.reducer