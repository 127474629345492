import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: {},
    isLoading: false,
    error: null
}

const getSupplierSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        supplierPending: (state) => {
            state.isLoading = true;
        },
        supplierSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.list = payload?.data;
            state.error = null;
        },
        supplierFail: (state, { payload }) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const { supplierPending, supplierSuccess, supplierFail } = getSupplierSlice.actions

export default getSupplierSlice.reducer