import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addCategory: {},
    isLoading: false,
    error: null
}

const addCategorySlice = createSlice({
    name: 'addCategory',
    initialState,
    reducers:{
        addCategoryPending: (state) => {
            state.isLoading = true;
        },
        addCategorySuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addCategory = payload?.add;
            state.error = null;
        },
        addCategoryFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addCategoryPending, addCategorySuccess, addCategoryFail} = addCategorySlice.actions

export default addCategorySlice.reducer