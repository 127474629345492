import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteAd: {},
    isLoading: false,
    error: null
}

const deleteAdSlice = createSlice({
    name: 'deleteAd',
    initialState,
    reducers:{
        deleteAdPending: (state) => {
            state.isLoading = true;
        },
        deleteAdSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteAd = payload?.result;
            state.error = null;
        },
        deleteAdFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteAdPending, deleteAdSuccess, deleteAdFail} = deleteAdSlice.actions

export default deleteAdSlice.reducer