import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editRole: {},
    isLoading: false,
    error: null
}

const editRoleSlice = createSlice({
    name: 'editRole',
    initialState,
    reducers:{
        editRolePending: (state) => {
            state.isLoading = true;
        },
        editRoleSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.editRole = payload?.response;
            state.error = null;
        },
        editRoleFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {editRolePending, editRoleSuccess, editRoleFail} = editRoleSlice.actions

export default editRoleSlice.reducer