import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteFeaturedProduct: {},
    isLoading: false,
    error: null
}

const deleteFeaturedProductSlice = createSlice({
    name: 'deleteFeaturedProduct',
    initialState,
    reducers: {
        deleteFeaturedProductPending: (state) => {
            state.isLoadingProducts = true;
        },
        deleteFeaturedProductSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.deleteFeaturedProduct = payload;
            state.error = null;
        },
        deleteFeaturedProductFail: (state, { payload }) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const { deleteFeaturedProductPending, deleteFeaturedProductSuccess, deleteFeaturedProductFail } = deleteFeaturedProductSlice.actions

export default deleteFeaturedProductSlice.reducer