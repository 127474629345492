import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading:false,
    isAuth:false,
    loggedIn: {},
    error:null
}
const loginSlice = createSlice({
    name:'login',
    initialState,
    reducers:{
        loginPending: (state)=>{
            state.isLoading = true;
        },
        loginSucess: (state, {payload}) =>{
            state.isLoading = false;
            state.isAuth = true;
            state.loggedIn = payload;
        },
        loginFail: (state, {payload}) =>{
            state.isLoading = false;
            state.error =Array.isArray(payload?.errors) ? payload : payload?.response?.data?.message;
        }
    }
})

const {reducer, actions} = loginSlice
export const {loginPending, loginSucess, loginFail} = actions;
export default reducer;