import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addRetailer: {},
    isLoading: false,
     error : null
}

const addRetailerSlice = createSlice({
    name: 'addRetailer',
    initialState,
    reducers:{
        addRetailerPending: (state) => {
            state.isLoadingRetailers = true;
        },
        addRetailerSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addRetailer = payload?.retailer;
            state.error = null;
        },
        addRetailerFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addRetailerPending, addRetailerSuccess, addRetailerFail} = addRetailerSlice.actions

export default addRetailerSlice.reducer