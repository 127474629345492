import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteCategory: {},
    isLoading: false,
    error: null
}

const deleteCategorySlice = createSlice({
    name: 'deleteCategory',
    initialState,
    reducers:{
        deleteCategoryPending: (state) => {
            state.isLoading = true;
        },
        deleteCategorySuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteCategory = payload;
            state.error = null;
        },
        deleteCategoryFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteCategoryPending, deleteCategorySuccess, deleteCategoryFail} = deleteCategorySlice.actions

export default deleteCategorySlice.reducer