import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getProducts: {},
    isLoadingProducts: false,
    errorGetProducts: ''
}

const getProductsSlice = createSlice({
    name: 'getProducts',
    initialState,
    reducers:{
        getProductsPending: (state) => {
            state.isLoadingProducts = true;
        },
        getProductsSuccess: (state, {payload}) => {
            state.isLoadingProducts = false;
            state.getProducts = payload.products;
            state.errorGetProducts = '';
        },
        getProductsFail: (state, {payload}) => {
            state.isLoadingProducts = false;
            state.errorGetProducts = payload;
        }
    }
})

export const {getProductsPending, getProductsSuccess, getProductsFail} = getProductsSlice.actions

export default getProductsSlice.reducer