import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteRole: {},
    isLoading: false,
    error: null
}

const deleteRoleSlice = createSlice({
    name: 'deleteRole',
    initialState,
    reducers:{
        deleteRolePending: (state) => {
            state.isLoading = true;
        },
        deleteRoleSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteRole = payload?.delete;
            state.error = null;
        },
        deleteRoleFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteRolePending, deleteRoleSuccess, deleteRoleFail} = deleteRoleSlice.actions

export default deleteRoleSlice.reducer