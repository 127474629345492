import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateFeaturedProduct: {},
    isLoading: false,
    error: null
}

const updateFeaturedProductSlice = createSlice({
    name: 'updateFeaturedProduct',
    initialState,
    reducers: {
        updateFeaturedProductPending: (state) => {
            state.isLoadingProducts = true;
        },
        updateFeaturedProductSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.updateFeaturedProduct = payload?.updated;
            state.error = null;
        },
        updateFeaturedProductFail: (state, { payload }) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const { updateFeaturedProductPending, updateFeaturedProductSuccess, updateFeaturedProductFail } = updateFeaturedProductSlice.actions

export default updateFeaturedProductSlice.reducer