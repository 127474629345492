import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateUser: {},
    isLoadingUpdateUser: false,
    errorUpdateUser: ''
}

const updateUserSlice = createSlice({
    name: 'updateUser',
    initialState,
    reducers:{
        updateUserPending: (state) => {
            state.isLoadingUpdateUser = true;
        },
        updateUserSuccess: (state, {payload}) => {
            state.isLoadingUpdateUser = false;
            state.updateUser = payload?.data;
            state.errorUpdateUser = '';
        },
        updateUserFail: (state, {payload}) => {
            state.isLoadingUpdateUser = false;
            state.errorUpdateUser = payload;
        }
    }
})

export const {updateUserPending, updateUserSuccess, updateUserFail} = updateUserSlice.actions

export default updateUserSlice.reducer