import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addBanner: {},
    isLoading: false,
    error: null
}

const addBannerSlice = createSlice({
    name: 'addBanner',
    initialState,
    reducers:{
        addBannerPending: (state) => {
            state.isLoading = true;
        },
        addBannerSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addBanner = payload?.add;
            state.error = null;
        },
        addBannerFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addBannerPending, addBannerSuccess, addBannerFail} = addBannerSlice.actions

export default addBannerSlice.reducer