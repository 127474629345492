import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    priceupdate: {},
    isLoading: false,
   error : null
}

const updatePriceSlice = createSlice({
    name: 'priceupdate',
    initialState,
    reducers:{
        updatePricePending: (state) => {
            state.isLoadingPrices = true;
        },
        updatePriceSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.priceupdate = payload?.updated;
            state.error = null;
        },
        updatePriceFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {updatePricePending, updatePriceSuccess, updatePriceFail} = updatePriceSlice.actions

export default updatePriceSlice.reducer