import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addFeaturedProducts: {},
    isLoading: false,
     error : null
}

const addFeaturedProductSlice = createSlice({
    name: 'addFeaturedProducts',
    initialState,
    reducers:{
        addFeaturedProductPending: (state) => {
            state.isLoading = true;
        },
        addFeaturedProductSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addFeaturedProducts = payload;
            state.error = null;
        },
        addFeaturedProductFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addFeaturedProductPending, addFeaturedProductSuccess, addFeaturedProductFail} = addFeaturedProductSlice.actions

export default addFeaturedProductSlice.reducer