import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateFeaturedShops: {},
    isLoadingFeaturedShop: false,
    errorFeaturedShop : null
}

const updateFeaturedShopSlice = createSlice({
    name: 'updateFeaturedShops',
    initialState,
    reducers:{
        updateFeaturedShopPending: (state) => {
            state.isLoadingFeaturedShop = true;
        },
        updateFeaturedShopSuccess: (state, {payload}) => {
            state.isLoadingFeaturedShop = false;
            state.updateFeaturedShops = payload?.result;
            state.errorFeaturedShop = null;
        },
        updateFeaturedShopFail: (state, {payload}) => {
            state.isLoadingFeaturedShop = false;
            state.errorFeaturedShop = payload;
        }
    }
})

export const {updateFeaturedShopPending, updateFeaturedShopSuccess, updateFeaturedShopFail} = updateFeaturedShopSlice.actions

export default updateFeaturedShopSlice.reducer