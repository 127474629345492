import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getFeaturedCategories: {},
    isLoadingFeaturedCategories: false,
    errorGetFeaturedCategories: ''
}

const getFeaturedCategorieslice = createSlice({
    name: 'getFeaturedCategories',
    initialState,
    reducers:{
        getFeaturedCategoriesPending: (state) => {
            state.isLoadingFeaturedCategories = true;
        },
        getFeaturedCategoriesSuccess: (state, {payload}) => {

            state.isLoadingFeaturedCategories = false;
            state.getFeaturedCategories = payload?.result;
            state.errorGetFeaturedCategories = '';
        },
        getFeaturedCategoriesFail: (state, {payload}) => {
            state.isLoadingFeaturedCategories = false;
            state.errorGetFeaturedCategories = payload;
        }
    }
})

export const {getFeaturedCategoriesPending, getFeaturedCategoriesSuccess, getFeaturedCategoriesFail} = getFeaturedCategorieslice.actions

export default getFeaturedCategorieslice.reducer