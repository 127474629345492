import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addBrand: {},
    isLoading: false,
    error: null
}

const addBrandSlice = createSlice({
    name: 'addBrand',
    initialState,
    reducers:{
        addBrandPending: (state) => {
            state.isLoading = true;
        },
        addBrandSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addBrand = payload?.add;
            state.error = null;
        },
        addBrandFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addBrandPending, addBrandSuccess, addBrandFail} = addBrandSlice.actions

export default addBrandSlice.reducer