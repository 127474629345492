import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateSupplier: {},
    isLoading: false,
   error : null
}

const updateSupplierSlice = createSlice({
    name: 'updateSupplier',
    initialState,
    reducers:{
        updateSupplierPending: (state) => {
            state.isLoadingSuppliers = true;
        },
        updateSupplierSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.updateSupplier = payload?.updated;
            state.error = null;
        },
        updateSupplierFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {updateSupplierPending, updateSupplierSuccess, updateSupplierFail} = updateSupplierSlice.actions

export default updateSupplierSlice.reducer