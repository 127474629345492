import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addPrice: {},
    isLoading: false,
     error : null
}

const addPriceSlice = createSlice({
    name: 'addPrice',
    initialState,
    reducers:{
        addPricePending: (state) => {
            state.isLoadingPrices = true;
        },
        addPriceSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addPrice = payload?.price;
            state.error = null;
        },
        addPriceFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addPricePending, addPriceSuccess, addPriceFail} = addPriceSlice.actions

export default addPriceSlice.reducer