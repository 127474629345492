import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getFeaturedProducts: {},
    isLoadingFeaturedProducts: false,
    errorGetFeaturedProducts: ''
}

const getFeaturedProductSlice = createSlice({
    name: 'getFeaturedProducts',
    initialState,
    reducers:{
        getFeaturedProductsPending: (state) => {
            state.isLoadingFeaturedProducts = true;
        },
        getFeaturedProductsSuccess: (state, {payload}) => {

            state.isLoadingFeaturedProducts = false;
            state.getFeaturedProducts = payload?.result;
            state.errorGetFeaturedProducts = '';
        },
        getFeaturedProductsFail: (state, {payload}) => {
            state.isLoadingFeaturedProducts = false;
            state.errorGetFeaturedProducts = payload;
        }
    }
})

export const {getFeaturedProductsPending, getFeaturedProductsSuccess, getFeaturedProductsFail} = getFeaturedProductSlice.actions

export default getFeaturedProductSlice.reducer