
import { createSlice } from '@reduxjs/toolkit';

const LogoutSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    isLoggedIn: true,
    isLoggedOut: {},
    errorLogout:''
  },
  reducers: {
    loggingOutPending : (state) =>{
      state.isLoggedIn = true;
      state.isLoading= true
    },
    loggingOutSuccess : (state, {payload}) => {
      state.isLoggedIn = payload;
      state.isLoggedIn=false;
      state.isLoading= false;
      state.errorLogout="";
    },
    loggingOutFail : (state, {payload}) => {
      state.isLoading=false;
      state.isLoggedIn = true;
      state.errorLogout=payload
    },
  },
});

export const { loggingOutPending, loggingOutSuccess, loggingOutFail } = LogoutSlice.actions;

export default LogoutSlice.reducer;
