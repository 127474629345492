import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteBrand: {},
    isLoading: false,
    error: null
}

const deleteBrandSlice = createSlice({
    name: 'deleteBrand',
    initialState,
    reducers:{
        deleteBrandPending: (state) => {
            state.isLoading = true;
        },
        deleteBrandSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteBrand = payload;
            state.error = null;
        },
        deleteBrandFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteBrandPending, deleteBrandSuccess, deleteBrandFail} = deleteBrandSlice.actions

export default deleteBrandSlice.reducer