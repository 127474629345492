import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateRetailer: {},
    isLoading: false,
   error : null
}

const updateRetailerSlice = createSlice({
    name: 'updateRetailer',
    initialState,
    reducers:{
        updateRetailerPending: (state) => {
            state.isLoadingRetailers = true;
        },
        updateRetailerSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.updateRetailer = payload?.updated;
            state.error = null;
        },
        updateRetailerFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {updateRetailerPending, updateRetailerSuccess, updateRetailerFail} = updateRetailerSlice.actions

export default updateRetailerSlice.reducer