import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addrole: {},
    isLoading: false,
    error: null
}

const addRoleSlice = createSlice({
    name: 'addrole',
    initialState,
    reducers:{
        addRolePending: (state) => {
            state.isLoading = true;
        },
        addRoleSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addsub= payload?.add;
            state.error = null;
        },
        addRoleFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addRolePending, addRoleSuccess, addRoleFail} = addRoleSlice.actions

export default addRoleSlice.reducer