import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editBrand: {},
    isLoading: false,
    error: null
}

const editBrandSlice = createSlice({
    name: 'editBrand',
    initialState,
    reducers:{
        editBrandPending: (state) => {
            state.isLoading = true;
        },
        editBrandSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.editBrand = payload?.response;
            state.error = null;
        },
        editBrandFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {editBrandPending, editBrandSuccess, editBrandFail} = editBrandSlice.actions

export default editBrandSlice.reducer