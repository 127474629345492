import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: {},
    isLoading: false,
    errors:''
}

const orderStatusSlice = createSlice({
    name: 'status',
    initialState,
    reducers:{
        orderStatusPending: (state) => {
            state.isLoading = true;
        },
        orderStatusSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.status = payload?.update;
            state.errors = '';
        },
        orderStatusFail: (state, {payload}) => {
            state.isLoading = false;
            state.errors = payload;
        }
    }
})

export const {orderStatusPending, orderStatusSuccess, orderStatusFail} = orderStatusSlice.actions

export default orderStatusSlice.reducer