import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteBanner: {},
    isLoading: false,
    error: null
}

const deleteBannerSlice = createSlice({
    name: 'deleteBanner',
    initialState,
    reducers:{
        deleteBannerPending: (state) => {
            state.isLoading = true;
        },
        deleteBannerSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteBanner = payload;
            state.error = null;
        },
        deleteBannerFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteBannerPending, deleteBannerSuccess, deleteBannerFail} = deleteBannerSlice.actions

export default deleteBannerSlice.reducer