import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateFeaturedShopStatus: {},
    isLoadingFeaturedShopStatus: false,
    errorFeaturedShopStatus : null
}

const updateFeaturedShopStatusSlice = createSlice({
    name: 'updateFeaturedShopStatus',
    initialState,
    reducers:{
        updateFeaturedShopStatusPending: (state) => {
            state.isLoadingFeaturedShopStatus = true;
        },
        updateFeaturedShopStatusSuccess: (state, {payload}) => {
            state.isLoadingFeaturedShopStatus = false;
            state.updateFeaturedShopStatus = payload?.result;
            state.errorFeaturedShopStatus = null;
        },
        updateFeaturedShopStatusFail: (state, {payload}) => {
            state.isLoadingFeaturedShopStatus = false;
            state.errorFeaturedShopStatus = payload;
        }
    }
})

export const {updateFeaturedShopStatusPending, updateFeaturedShopStatusSuccess, updateFeaturedShopStatusFail} = updateFeaturedShopStatusSlice.actions

export default updateFeaturedShopStatusSlice.reducer