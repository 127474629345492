import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    orders: {},
    isLoading: false,
    errors:''
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers:{
        ordersPending: (state) => {
            state.isLoadingUsers = true;
        },
        ordersSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.orders = payload?.orders;
            state.errors = '';
        },
        ordersFail: (state, {payload}) => {
            state.isLoading = false;
            state.errors = payload;
        }
    }
})

export const {ordersPending, ordersSuccess, ordersFail} = ordersSlice.actions

export default ordersSlice.reducer