import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    featuredProductStatus: {},
    isLoading: false,
    error: null
}

const featuredProductStatusSlice = createSlice({
    name: 'featuredProductStatus',
    initialState,
    reducers: {
        featuredProductStatusPending: (state) => {
            state.isLoadingProducts = true;
        },
        featuredProductStatusSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.featuredProductStatus = payload?.deleted;
            state.error = null;
        },
        featuredProductStatusFail: (state, { payload }) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const { featuredProductStatusPending, featuredProductStatusSuccess, featuredProductStatusFail } = featuredProductStatusSlice.actions

export default featuredProductStatusSlice.reducer