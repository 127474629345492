import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addCatalogue: {},
    isLoading: false,
    error: null
}

const addCatalogueSlice = createSlice({
    name: 'addCatalogue',
    initialState,
    reducers:{
        addCataloguePending: (state) => {
            state.isLoading = true;
        },
        addCatalogueSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addCatalogue = payload?.add;
            state.error = null;
        },
        addCatalogueFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addCataloguePending, addCatalogueSuccess, addCatalogueFail} = addCatalogueSlice.actions

export default addCatalogueSlice.reducer