import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ads: {},
    isLoading: false,
   error :null
}

const getAdsSlice = createSlice({
    name: 'ads',
    initialState,
    reducers:{
        adsPending: (state) => {
            state.isLoading = true;
        },
        adsSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.ads = payload?.result;
            state.error = null;
        },
        adsFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {adsPending, adsSuccess, adsFail} = getAdsSlice.actions

export default getAdsSlice.reducer