import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editAdProduct: {},
    isLoading: false,
    error: null
}

const editAdProductSlice = createSlice({
    name: 'editAdProduct',
    initialState,
    reducers:{
        editAdProductPending: (state) => {
            state.isLoading = true;
        },
        editAdProductSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.editAdProduct = payload?.response;
            state.error = null;
        },
        editAdProductFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {editAdProductPending, editAdProductSuccess, editAdProductFail} = editAdProductSlice.actions

export default editAdProductSlice.reducer