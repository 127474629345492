import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteCatalogue: {},
    isLoading: false,
    error: null
}

const deleteCatalogueSlice = createSlice({
    name: 'deleteCatalogue',
    initialState,
    reducers:{
        deleteCataloguePending: (state) => {
            state.isLoading = true;
        },
        deleteCatalogueSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteCatalogue = payload;
            state.error = null;
        },
        deleteCatalogueFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteCataloguePending, deleteCatalogueSuccess, deleteCatalogueFail} = deleteCatalogueSlice.actions

export default deleteCatalogueSlice.reducer