import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    roles: {},
    loading: false,
    error: null
}

const roleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers:{
        rolesPending: (state) => {
            state.loading = true;
        },
        rolesSuccess: (state, {payload}) => {
            state.loading = false;
            state.roles= payload?.roles;
            state.error = null;
        },
        rolesFail: (state, {payload}) => {
            state.loading = false;
            state.error = payload;
        }
    }
})

export const {rolesPending, rolesSuccess, rolesFail} = roleSlice.actions

export default roleSlice.reducer