import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    catalogues: {},
    isLoading: false,
   error :null
}

const getCataloguesSlice = createSlice({
    name: 'catalogues',
    initialState,
    reducers:{
        cataloguesPending: (state) => {
            state.isLoading = true;
        },
        cataloguesSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.catalogues = payload?.result;
            state.error = null;
        },
        cataloguesFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {cataloguesPending, cataloguesSuccess, cataloguesFail} = getCataloguesSlice.actions

export default getCataloguesSlice.reducer