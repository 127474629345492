import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteProduct: {},
    isLoading: false,
   error : null
}

const deleteProductSlice = createSlice({
    name: 'deleteProduct',
    initialState,
    reducers:{
        deleteProductPending: (state) => {
            state.isLoadingProducts = true;
        },
        deleteProductSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteProduct = payload?.deleted;
            state.error = null;
        },
        deleteProductFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteProductPending, deleteProductSuccess, deleteProductFail} = deleteProductSlice.actions

export default deleteProductSlice.reducer