import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categories: {},
    isLoading: false,
   error :null
}

const getCategoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers:{
        categoriesPending: (state) => {
            state.isLoading = true;
        },
        categoriesSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.categories = payload?.result;
            state.error = null;
        },
        categoriesFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {categoriesPending, categoriesSuccess, categoriesFail} = getCategoriesSlice.actions

export default getCategoriesSlice.reducer