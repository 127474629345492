import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    price: {},
    loading: false,
    error: ''
}

const getPriceSlice = createSlice({
    name: 'price',
    initialState,
    reducers:{
        getPricePending: (state) => {
            state.loading = true;
        },
        getPriceSuccess: (state, {payload}) => {
            state.loading = false;
            state.price = payload?.price;
            state.error = '';
        },
        getPriceFail: (state, {payload}) => {
            state.loading = false;
            state.error = payload;
        }
    }
})

export const {getPricePending, getPriceSuccess, getPriceFail} = getPriceSlice.actions

export default getPriceSlice.reducer