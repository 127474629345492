import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addFeaturedCategories: {},
    isLoadingFeaturedCategory: false,
    errorFeaturedCategory : null
}

const addFeaturedCategorySlice = createSlice({
    name: 'addFeaturedCategories',
    initialState,
    reducers:{
        addFeaturedCategoryPending: (state) => {
            state.isLoadingFeaturedCategory = true;
        },
        addFeaturedCategorySuccess: (state, {payload}) => {
            state.isLoadingFeaturedCategory = false;
            state.addFeaturedCategories = payload?.result;
            state.errorFeaturedCategory = null;
        },
        addFeaturedCategoryFail: (state, {payload}) => {
            state.isLoadingFeaturedCategory = false;
            state.errorFeaturedCategory = payload;
        }
    }
})

export const {addFeaturedCategoryPending, addFeaturedCategorySuccess, addFeaturedCategoryFail} = addFeaturedCategorySlice.actions

export default addFeaturedCategorySlice.reducer