import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteRetailer: {},
    isLoading: false,
   error : null
}

const deleteRetailerSlice = createSlice({
    name: 'deleteRetailer',
    initialState,
    reducers:{
        deleteRetailerPending: (state) => {
            state.isLoadingRetailers = true;
        },
        deleteRetailerSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteRetailer = payload?.deleted;
            state.error = null;
        },
        deleteRetailerFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteRetailerPending, deleteRetailerSuccess, deleteRetailerFail} = deleteRetailerSlice.actions

export default deleteRetailerSlice.reducer