import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateFeaturedCategories: {},
    isLoadingFeaturedCategory: false,
    errorFeaturedCategory : null
}

const updateFeaturedCategorySlice = createSlice({
    name: 'updateFeaturedCategories',
    initialState,
    reducers:{
        updateFeaturedCategoryPending: (state) => {
            state.isLoadingFeaturedCategory = true;
        },
        updateFeaturedCategorySuccess: (state, {payload}) => {
            state.isLoadingFeaturedCategory = false;
            state.updateFeaturedCategories = payload?.result;
            state.errorFeaturedCategory = null;
        },
        updateFeaturedCategoryFail: (state, {payload}) => {
            state.isLoadingFeaturedCategory = false;
            state.errorFeaturedCategory = payload;
        }
    }
})

export const {updateFeaturedCategoryPending, updateFeaturedCategorySuccess, updateFeaturedCategoryFail} = updateFeaturedCategorySlice.actions

export default updateFeaturedCategorySlice.reducer