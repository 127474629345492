import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addAd: {},
    isLoading: false,
    error: null
}

const addAdSlice = createSlice({
    name: 'addAd',
    initialState,
    reducers:{
        addAdPending: (state) => {
            state.isLoading = true;
        },
        addAdSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addAd = payload?.add;
            state.error = null;
        },
        addAdFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addAdPending, addAdSuccess, addAdFail} = addAdSlice.actions

export default addAdSlice.reducer