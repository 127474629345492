import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    add: {},
    isLoading: false,
 error : null
}

const AddUserSlice = createSlice({
    name: 'add',
    initialState,
    reducers:{
        addPending: (state) => {
            state.isLoading = true;
        },
        addSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.add = payload?.data;
            state.error = null;
        },
        addFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addPending, addSuccess, addFail} = AddUserSlice.actions

export default AddUserSlice.reducer
